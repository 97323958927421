<template>
    <div class="page-table scrollable only-y mainDiv" id="affix-container">
        <div class="card-base baslikBg animated fadeInUp">
            {{$t("src.views.apps.sayfa.liste.title")}}

            <div class="tanimlamaButon">
                <el-tooltip :content='$t("src.views.apps.genel.refresh")' :open-delay="500" placement="top">
                    <el-button
                        v-on:click="refreshPage()"
                        type="text"
                        style="margin-left: 15px !important"
                        icon="el-icon-refresh">
                    </el-button>
                </el-tooltip>

                <el-tooltip :content='$t("src.views.apps.genel.yeniKaydet")'
                    :open-delay="500"
                    placement="bottom">
                    <el-button
                        v-on:click="route('Sayfa Tanımla')"
                        type="text"
                        style="margin-left: 15px !important"
                        icon="el-icon-plus">
                    </el-button>
                </el-tooltip>
            </div>
        </div>
        
        <div class="page-header card-base card-shadow--medium animated fadeInUp pt-20" v-loading="loading || stateLoading"
            :element-loading-text='loading ? $t("src.views.apps.sayfa.liste.listLoading") : $t("src.views.apps.sayfa.liste.stateLoading")'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)">
            <el-row class="mt-0 mb-10">
                <el-col :lg="12" :md="12" :sm="24" :xs="24">
                    <div>
                        <el-radio-group size="small" v-on:change="handleChange($event)" v-model="radio" fill="#E5F5F9" text-color="gray">
                            <el-radio-button label="1">
                                <i class="mdi mdi-page-next mdi-18px" style="color: #13ce66; margin-right: 5px"></i>
                                    <label v-if="selectIcon==='1'">{{$t("src.views.apps.sayfa.liste.yayinda")}}</label>
                            </el-radio-button>
                            <el-radio-button label="0">
                                <i class="mdi mdi-page-next mdi-18px" style="color: #ec205f; margin-right: 5px"></i>
                                    <label v-if="selectIcon==='0'">{{$t("src.views.apps.sayfa.liste.pasif")}}</label>
                            </el-radio-button>
                        </el-radio-group>
                    </div>

                    
                </el-col>
                <el-col :lg="12" :md="12" :sm="24" :xs="24">
                    <el-col :lg="24" :md="24" :sm="24" :xs="24">
                        <ul class="islemler">
                            <!-- <li class="detay">
                                <i class="mdi mdi-account"></i> Oluşturan
                            </li> -->
                            <li class="goruntule">
                                <i class="mdi mdi-redo-variant"></i> {{$t("src.views.apps.sayfa.liste.goruntule")}}
                            </li>
                            <li class="duzenle">
                                <i class="mdi mdi-pen"></i> {{$t("src.views.apps.sayfa.liste.duzenle")}}
                            </li>
                            <li v-if="selectIcon === '1'" class="sil">
                                <i class="mdi mdi-trash-can"></i> {{$t("src.views.apps.sayfa.liste.sil")}}
                            </li>
                            <li v-else class="aktif">
                                <i class="mdi mdi-play"></i> {{$t("src.views.apps.sayfa.liste.yayinaAl")}}
                            </li>
                        </ul>
                    </el-col>
                </el-col>
            </el-row>

            <el-table  v-loading="searchLoading"
            element-loading-text='Sayfa Aranıyor. Lütfen Bekleyin.'
            element-loading-spinner="el-icon-loading"
            element-loading-background="rgba(255, 255, 255, 1)"
            border stripe :data="sayfaListe" style="width: 100%">
                <el-table-column
                :label='$t("src.views.apps.genel.sira")'
                type="index"
                :index="indexMethod"
                width="100">
                </el-table-column>

                <el-table-column :label='$t("src.views.apps.urun.liste.table.image")'  width="100" align="center">
                    <template slot-scope="scope">
                        <el-popover transition="el-fade-in-linear" placement="right" width="auto" trigger="hover">
                            <img v-if="scope.row.resim" style="max-height: 600px" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <span v-else>Resim Yok.</span>
                            <img v-if="scope.row.resim" style="max-height: 50px !important" slot="reference" :src="imagepath+scope.row.resim" :alt="scope.row.baslik">
                            <i v-else style="font-size: 24px; cursor: pointer; color: #337ab7" slot="reference" class="el-icon-picture"></i>
                        </el-popover>
                    </template>
                </el-table-column>
                
                <el-table-column
                    prop="sayfaID"
                    :label='$t("src.views.apps.sayfa.liste.table.sayfaID")'
                    width="100">
                </el-table-column>

                <el-table-column
                    prop="baslik"
                    :label='$t("src.views.apps.sayfa.liste.table.baslik")'
                    width="auto">
                    <template slot-scope="scope ">
                        <div class="new-line-text">{{scope.row.baslik}}</div>
                    </template>
                </el-table-column>

                <el-table-column
                    prop="link"
                    label="Link"
                    width="auto">
                </el-table-column>

                <el-table-column
                    :label='$t("src.views.apps.genel.islem")'
                    align="right"
                    width="200">
                    
                    <template slot="header" slot-scope="scope">
                        <el-input clearable v-on:clear="sayfaAra" v-model="baslikAra" v-debounce:700ms="sayfaAra" placeholder="Sayfa Ara" size="mini"></el-input>
                    </template>

                    <template slot-scope="scope">
                        <el-tooltip content='Sayfa Görüntüle' :open-delay="500" placement="bottom">
                            <el-link target="_blank" icon="mdi mdi-redo-variant mdi-24px" class="islem-button ml-15" :underline="false"
                            style="position: relative; bottom: 3px"
                            :href="path+scope.row.link">
                            </el-link>
                        </el-tooltip>

                        <el-tooltip content='Sayfa Düzenle' :open-delay="500" placement="bottom">
                            <el-button
                                v-on:click="route('Sayfa Güncelle',scope.row)"
                                type="text"
                                class="islem-button"
                                style="color: #f7ba2a; margin-left: 15px !important;"
                                icon="mdi mdi-pen mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayindanKaldir")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon==='1'"
                                class="buttonDel"
                                style="margin-left: 15px !important;"
                                type="text"
                                @click="changeSayfaDurum(scope.row, '0')"
                                icon="mdi mdi-trash-can mdi-24px">
                            </el-button>
                        </el-tooltip>

                        <el-tooltip :content='$t("src.views.apps.sayfa.liste.yayinaAl")' :open-delay="500" placement="bottom">
                            <el-button
                                v-if="selectIcon==='0'"
                                @click="changeSayfaDurum(scope.row, '1')"
                                type="text"
                                style="margin-left: 15px !important;"
                                class="islem-button-play islem-button ml-15"
                                icon="mdi mdi-play mdi-24px">
                            </el-button>
                        </el-tooltip>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                layout="sizes,prev, pager, next, jumper, total"
                :total="total"
                @size-change="handleSizeChange"
                @current-change="pageChange"
                :page-sizes="[10, 20, 50, 100]"
                :current-page.sync="page"
                :next-click.sync="page"
                :prev-click.sync="page"
                @next-click="pageChange"
                @prev-click="pageChange">
            </el-pagination>
        </div>

    </div>
</template>

<script>
    import sayfaService from '../../../WSProvider/SayfaService'
    import notification from '../../../notification'
    import EventBus from '@/components/event-bus'
    import functions from '../../../functions'
    import JQuery from 'jquery';

    let $ = JQuery;

    export default {
        name: "SayfaListesi",
        components: {
        },
        data() {
            return {
                imagepath: sayfaService.imagePath,
                path: sayfaService.path,
                loading: false,
                stateLoading: false,
                searchLoading: false,
                selectIcon: "1",
                baslikAra: "",
                radio: '1',

                //Table
                sizes: 10,
                postPage: 0,
                total: 0,
                page: 0,
                sayfaListe: [],
                selection: '',
            }
        },
        mounted() {
            let self = this;

            this.getSayfaList();
            this.getSayfaCount();

            EventBus.$on('sayfaList', function (bool) {
                if (bool) {
                    self.page = 1;
                    self.postPage = 0;
                    self.getSayfaList();
                    this.getSayfaCount();
                    self.baslikAra = ''
                }
            });
        },
        methods: {
            getSayfaCount(){
                try{
                    sayfaService.sayfaDurumSayisi(this.selectIcon).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            this.total = response.data;
                        }
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.total = 0
                            }
                        }
                    })
                }catch(e){

                }
            },

            refreshPage(){
                this.loading = false;
                this.stateLoading = false;
                this.page = 1;
                this.postPage = 0;
                this.getSayfaList();
                this.getSayfaCount();
            }, 

            
            sayfaAra(val){
                if(this.baslikAra.length > 0){
                    try{
                        this.searchLoading = true;
                        sayfaService.sayfaAra(this.baslikAra, this.selectIcon, this.postPage, this.sizes).then((response => {
                            if(response.status == 200){
                                this.sayfaListe = response.data;
                                this.total = response.count;
                            }
                            this.searchLoading = false;
                        })).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }else if(error.status == 404){
                                    this.sayfaListe = [];
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.searchLoading = false;
                        })
                    }catch(e){
                        this.searchLoading = false;
                    }
                }else{
                    this.getSayfaList()
                    this.getSayfaCount();
                }
            },

            handleChange(event) {
                this.selectIcon = event;
                this.page = 1;
                this.postPage = 0;
                this.baslikAra = "";
                this.getSayfaList();
                this.getSayfaCount();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },

            getSayfaList() {
                try{
                    this.loading = true;
                    sayfaService.sayfaList(this.postPage, this.sizes, this.selectIcon).then((response) => {
                        if (response.status == 200) {
                            localStorage.setItem("userDataDemirag", response.token);
                            response.data.forEach(element => {
                                element = functions.removeSlashesAndDecodeStrings(element)
                            });
                            this.sayfaListe = response.data;
                        }
                        this.loading = false;
                    }).catch(err => {
                        if(err.responseJSON){
                            let error = err.responseJSON
                            if(error.status == 401){
                                notification.Status(150, this);
                            }else if(error.status == 404){
                                this.sayfaListe = [];
                            }
                            else notification.Status("errorMsg", this, error.errMsg)
                        }
                        this.loading = false;
                    })
                }catch(e){
                    this.loading = false;
                }
            },

            changeSayfaDurum(data, durum) {
                var msg = durum == 0 ? "Sayfayı yayından kaldırmak istediğinize emin misiniz?" : "Sayfayı yayına almak istediğinize emin misiniz?"
                this.openConfirmDialog(msg).then(() => {
                    try{
                        this.stateLoading = true;
                        sayfaService.sayfaDurumGuncelle(data.sayfaID, durum).then(response => {
                            if(response.status == 200){
                                this.sayfaAra()
                                localStorage.setItem("userDataDemirag", response.token)
                            }
                            notification.Status("success", this, response.msg);
                            this.stateLoading = false;
                        }).catch(err => {
                            if(err.responseJSON){
                                let error = err.responseJSON
                                if(error.status == 401){
                                    notification.Status(150, this);
                                }
                                else notification.Status("errorMsg", this, error.errMsg)
                            }else{
                                //notification.Status(503, this)
                            }
                            this.stateLoading = false;
                        })
                    }catch(e){
                        this.stateLoading = false;
                    }
                })
            },

            handleSizeChange(val) {
                this.sizes = val;
                this.sayfaAra();
            },
            pageChange(val) {
                this.postPage = (val - 1) * this.sizes;
                this.sayfaAra();
                document.querySelector('.scrollable').scrollTo({top: 0, behavior: 'smooth'});
            },
            indexMethod(index) {
                return ((this.page - 1)* 10) + index + 1;
            },
            route(name, scope) {
                if (scope) {
                    this.$store.commit('changeSayfaData', scope);
                }
                this.routeSayfa(name)
            },
        }
    }
</script>

<style lang="scss">
    .el-pagination {
        margin-top: 20px;
        float: right;
    }

    .slide-fade-enter-active {
        transition: all .3s ease;
    }

    .islem-button{
        margin-right: 5%;
    }

    .islem-button-play{
        margin: 0 !important;
    }


    @media (min-width: 768px) {
        .el-radio-button--mini .el-radio-button__inner {
            padding: 0 10px !important;
            font-size: 11px;
            border-radius: 0;
        }
    }

    .slide-fade-leave-active {
        transition: all .3s cubic-bezier(1.0, 0.5, 0.8, 1.0);
    }

    .slide-fade-enter, .slide-fade-leave-to
        /* .slide-fade-leave-active below version 2.1.8 */
    {
        transform: translateX(10px);
        opacity: 0;
    }
</style>
<style lang="scss" scoped>
    .dangerbtn {
        color: #ec205f;
        position: absolute;
        bottom: -12px;
        right: -24px;
    }

    .successbtn {
        color: #13ce66;
        position: absolute;
        top: -12px;
        right: -24px;
    }

    .el-button--mini {
        padding: 0px 15px !important;
        height: 25px !important;
    }
</style>
